import React, { useState } from "react";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import TextEditor from "bundles/Internal/components/TextEditor";
import { useSnackbar } from "notistack";
import CardSelect from "bundles/Internal/components/CardSelect";
import { strip } from "bundles/common/utils/helpers";

const Description = ({ values, setValues, handleNextStep, goPreviousStep }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState([]);

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });
  };

  const handleTextEditor = (fieldName, value) => {
    if (values[fieldName] != value) {
      let newValues = { ...values, [fieldName]: value };
      setValues(newValues);
      validate({ fieldName: value });
    }
  };

  const handleCardSelected = (name, value, multiple) => {
    if (multiple) {
      let currentValues = values[name] || [];
      if (currentValues.includes(value)) {
        setValues({
          ...values,
          [name]: currentValues.filter((v) => v !== value),
        });
      } else {
        if (currentValues.length < 15) {
          setValues({ ...values, [name]: currentValues.concat([value]) });
        } else {
          enqueueSnackbar("You can select a maximum of 15 benefits.", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const benefitOptions = [
    { code: "company_car", label: "Company Car" },
    { code: "meal_vouchers", label: "Meal Vouchers" },
    { code: "fuel_card", label: "Fuel Card" },
    { code: "mobility_budget", label: "Mobility Budget" },
    { code: "net_expenses", label: "Net Expenses" },
    { code: "company_phone_subscription", label: "Company Phone & Subscription" },
    { code: "internal_external_trainings", label: "Internal & External Trainings" },
    { code: "end_of_year_bonuses", label: "End-of-year Bonuses" },
    { code: "group_insurance", label: "Group Insurance" },
    { code: "health_insurance", label: "Health Insurance" },
    { code: "bonus", label: "Bonus" },
    { code: "commissions", label: "Commissions" },
    { code: "stock_options", label: "Stock-options" },
    { code: "bspce", label: "BSPCE" },
    { code: "cafetaria_plan", label: "Cafeteria Plan" },
    { code: "beneficiary_bonus", label: "Beneficiary Bonus" },
    { code: "cba90", label: "CBA90" },
    { code: "open_space", label: "Open Space" },
    { code: "parking", label: "Parking" },
    { code: "nursery", label: "Nursery" },
    { code: "team_building_incentives", label: "Team-building & Incentives" },
    { code: "remote_possible", label: "Remote Possible" },
    { code: "1_day_remote", label: "1 Day Remote" },
    { code: "2_days_remote", label: "2 Days Remote" },
    { code: "3_days_remote", label: "3 Days Remote" },
    { code: "4_days_remote", label: "4 Days Remote" },
    { code: "full_remote", label: "Full Remote" },
    { code: "flexible_holidays", label: "Flexible Holidays" },
    { code: "20_days_off", label: "20 Days Off" },
    { code: "26_days_off", label: "26 Days Off" },
    { code: "30_days_off", label: "30 Days Off" },
    { code: "32_days_off", label: "32 Days Off" },
    { code: "flexible_working_hours", label: "Flexible Working Hours" },
    { code: "hiring_bonus", label: "Hiring Bonus" }
  ];

  const validateAndGoNext = (e) => {
    e.preventDefault();

    if (validate(values)) {
      handleNextStep();
    }
  };

  const validate = (fieldValues = {}) => {
    let temp = { ...errors };
    let required = "Required";

    if ("description" in fieldValues) {
      temp.description = fieldValues.description && strip(fieldValues.description)?.replaceAll(" ", "") ? "" : required;
    }

    if ("role_title" in fieldValues) {
      temp.role_title = fieldValues.role_title && strip(fieldValues.role_title)?.replaceAll(" ", "") ? "" : required;
    }

    if ("role" in fieldValues) {
      temp.role = fieldValues.role && strip(fieldValues.role)?.replaceAll(" ", "") ? "" : required;
    }

    if ("profile_title" in fieldValues) {
      temp.profile_title = fieldValues.profile_title && strip(fieldValues.profile_title)?.replaceAll(" ", "") ? "" : required;
    }

    if ("profile" in fieldValues) {
      temp.profile = fieldValues.profile && strip(fieldValues.profile)?.replaceAll(" ", "") ? "" : required;
    }

    if ("offer_title" in fieldValues) {
      temp.offer_title = fieldValues.offer_title && strip(fieldValues.offer_title)?.replaceAll(" ", "") ? "" : required;
    }

    if ("offer" in fieldValues) {
      temp.offer = fieldValues.offer && strip(fieldValues.offer)?.replaceAll(" ", "") ? "" : required;
    }

    if ("benefits" in fieldValues) {
      temp.benefits = fieldValues.benefits?.length > 0 ? "" : required;
    }

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  return (
    <Box>
      <h2>Description</h2>
      <Grid container spacing={2} component="form" onSubmit={validateAndGoNext}>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>What is the job description?</h3>
            </Box>
            <Box style={{ flexGrow: 1, width: "100%", height: "300px" }}>
              <TextEditor
                value={values.description}
                error={errors["description"]}
                handleChange={(v) => handleTextEditor("description", v)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>What is the role?</h3>
            </Box>
            <Box style={{ display: "flex", alignItems: "center", columnGap: "8px", marginBottom: "8px", width: "100%" }}>
              <Box>Role title:</Box>
              <TextField
                size="small"
                value={values.role_title}
                name="role_title"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                style={{ flexGrow: 1 }}
                {...(errors["role_title"] && {
                  error: true,
                  helperText: errors["role_title"],
                })}
              />
            </Box>
            <Box style={{ flexGrow: 1, width: "100%", height: "300px" }}>
              <TextEditor
                value={values.role}
                error={errors["role"]}
                handleChange={(v) => handleTextEditor("role", v)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>What is the profile?</h3>
            </Box>
            <Box style={{ display: "flex", alignItems: "center", columnGap: "8px", marginBottom: "8px", width: "100%" }}>
              <Box>Profile title:</Box>
              <TextField
                size="small"
                value={values.profile_title}
                name="profile_title"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                style={{ flexGrow: 1 }}
                {...(errors["profile_title"] && {
                  error: true,
                  helperText: errors["profile_title"],
                })}
              />
            </Box>
            <Box style={{ flexGrow: 1, width: "100%", height: "300px" }}>
              <TextEditor
                value={values.profile}
                error={errors["profile"]}
                handleChange={(v) => handleTextEditor("profile", v)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", rowGap: "8px", alignItems: "start", flexDirection: "column" }}>
            <Box style={{ minWidth: "100px"}}>
              <h3>What is the offer?</h3>
            </Box>
            <Box style={{ display: "flex", alignItems: "center", columnGap: "8px", marginBottom: "8px", width: "100%" }}>
              <Box>Offer title:</Box>
              <TextField
                size="small"
                value={values.offer_title}
                name="offer_title"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                style={{ flexGrow: 1 }}
                {...(errors["offer_title"] && {
                  error: true,
                  helperText: errors["offer_title"],
                })}
              />
            </Box>
            <Box style={{ flexGrow: 1, width: "100%", height: "300px" }}>
              <TextEditor
                value={values.offer}
                error={errors["offer"]}
                handleChange={(v) => handleTextEditor("offer", v)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ marginTop: "16px" }}>Benefits:</Box>
          <Typography style={{ color: "grey", fontSize: "14px", marginBottom: "16px" }}>
            Select up to 15 maximum
          </Typography>
          <Box style={{ flexGrow: 1 }}>
            <CardSelect
              fieldName="benefits"
              selected={values.benefits}
              multiple={true}
              values={benefitOptions}
              onChange={handleCardSelected}
            />
            {errors["benefits"] && (
              <p className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error" style={{ color: "#f44336" }}>
                {errors["benefits"]}
              </p>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <hr style={{  marginTop: "16px", marginBottom: "32px" }} />
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="squared" color="primaryOutlined" onClick={goPreviousStep}>Back</Button>
            <Button variant="squared" color="primaryContained" type="submit">Next</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Description;
