import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import validator from "validator";
import { Box, Button, Container, Divider, InputLabel, Link, TextField, Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import InfoIcon from "@mui/icons-material/Info";
import ReactPhoneInput from "react-phone-input-material-ui";
import "react-phone-input-material-ui/lib/style.css";
import sh from "bundles/common/utils/sh";
import { updateCurrentUser } from "bundles/common/reducers/user";
import { testUrl } from "bundles/common/utils/helpers";

const Profile = () => {
  const currentUser = useSelector((state) => state.current_user);
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const initialValues = {
    id: currentUser.id,
    first_name: currentUser.first_name,
    last_name: currentUser.last_name,
    linkedin_account: currentUser.linkedin_account,
    phone: currentUser.phone,
    birthday: currentUser.birthday
  };

  const [loading, setLoading] = useState(false);
  const [basicValues, setBasicValues] = useState(initialValues);
  const [basicErrors, setBasicErrors] = useState([]);
  const [passwordValues, setPasswordValues] = useState({});
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [hasValidGravatar, setHasValidGravatar] = useState(false);

  const validatePasswordValues = (fieldValues = passwordValues) => {
    let temp = { ...passwordErrors };

    let required = "Required";
    let notAStrongPassword = "Your password should be at least 8 characters long and include 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol";

    if ("currentPassword" in fieldValues) {
      temp.currentPassword = fieldValues.currentPassword ? "" : required;
    }
    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
      if (fieldValues.password) {
        if (!validator.isStrongPassword(fieldValues.password))
          temp.password = notAStrongPassword;
        else {
          temp.password = "";
        }
      }
    }

    setPasswordErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const validateBasicValues = (fieldValues = basicValues) => {
    let temp = { ...basicErrors };

    let required = "Required";

    if ("first_name" in fieldValues) {
      temp.first_name = fieldValues.first_name ? "" : required;
    }

    if ("last_name" in fieldValues) {
      temp.last_name = fieldValues.last_name ? "" : required;
    }

    if ("linkedin_account" in fieldValues) {
      temp.linkedin_account = fieldValues.linkedin_account ? "" : required;
    }

    if ("phone" in fieldValues) {
      temp.phone = fieldValues.phone ? "" : required;
    }

    if ("birthday" in fieldValues) {
      temp.birthday = fieldValues.birthday ? "" : required;
    }

    setBasicErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handlePasswordValue = (e) => {
    const { name, value } = e.target;
    setPasswordValues({ ...passwordValues, [name]: value });
    validatePasswordValues({ [name]: value });
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setBasicValues({ ...basicValues, [name]: value });
    validateBasicValues({ [name]: value });
  };

  const handlePhoneValue = (phone) => {
    setBasicValues({ ...basicValues, phone: phone });
    validateBasicValues({ phone: phone });
  };

  const handleLinkedInAccount = (e) => {
    const value = e.target.value;

    let account = "";
    if (value.includes("linkedin.com/in/")) {
      account = value.split("linkedin.com/in/")[1].split("/")[0];
    } else {
      account = value;
    }

    if(account.charAt(account.length-1) == "/") {
      account = account.slice(0, -1);
    }

    setBasicValues({ ...basicValues, linkedin_account: account });
    validateBasicValues({ linkedin_account: value });
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    if (validatePasswordValues(passwordValues)) {
      setLoading(true);
      sh.put("/internal/update_password", {
        authenticity_token: authenticityToken,
        user: {
          id: currentUser?.id,
          current_password: passwordValues.currentPassword,
          password: passwordValues.password,
        },
      })
        .then(() => {
          enqueueSnackbar(
            "Your password has been succesfully changed!",
            { variant: "success" }
          );
        })
        .catch((err) => {
          //Devise returns a node "current password" when the current password is invalid
          if (err?.response?.data?.current_password) {
            enqueueSnackbar(
              "Your current password is invalid",
              { variant: "error" }
            );
          } else {
            //example include: session time-out, clean cookies, ...
            enqueueSnackbar(
              "Oops, an error occured.",
              { variant: "error" }
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleBasicSubmit = (e) => {
    e.preventDefault();
    if (validateBasicValues(basicValues)) {
      setLoading(true);
      sh.put("internal/users", {
        authenticity_token: authenticityToken,
        user: basicValues
      }).then(() => {
        updateCurrentUser(dispatch, authenticityToken);
        enqueueSnackbar("Changes saved!", {
          variant: "success",
        });
      }).catch(() => {
        enqueueSnackbar(
          "Oops, an error occured.",
          { variant: "error" }
        );
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    let status = testUrl(currentUser.avatar_url);
    if (status != 404) {
      setHasValidGravatar(true);
    } else {
      setHasValidGravatar(false);
    }
  }, [currentUser.avatar_url]);

  return (
    <Container maxWidth="xl" style={{ paddingTop: "32px" }}>
      <Box>
        <h1>Basic informations</h1>
        <Box
          style={{ maxWidth: "min(100%, 560px)", display: "flex", flexDirection: "column", rowGap: "32px" }}
          component="form"
          onSubmit={handleBasicSubmit}
        >
          <Box style={{ display: "flex", columnGap: "16px"}}>
            <Box style={{ width: "50%" }}>
              <InputLabel>First name</InputLabel>
              <TextField
                fullWidth
                required
                size="small"
                type="text"
                variant="outlined"
                value={basicValues.first_name}
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name="first_name"
                {...(basicErrors["first_name"] && {
                  error: true,
                  helperText: basicErrors["first_name"],
                })}
              />
            </Box>
            <Box style={{ width: "50%" }}>
              <InputLabel>Last name</InputLabel>
              <TextField
                fullWidth
                required
                size="small"
                type="text"
                variant="outlined"
                value={basicValues.last_name}
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name="last_name"
                {...(basicErrors["last_name"] && {
                  error: true,
                  helperText: basicErrors["last_name"],
                })}
              />
            </Box>
          </Box>
          <Box style={{ display: "flex", columnGap: "16px"}}>
            <Box style={{width: "50%"}}>
              <InputLabel>Phone</InputLabel>
              <ReactPhoneInput
                value={basicValues.phone}
                defaultCountry="be"
                onlyCountries={["fr", "be", "de", "nl", "lu"]}
                country="be"
                onChange={handlePhoneValue}
                inputProps={basicErrors["phone"] ? {
                  error: true,
                  helperText: basicErrors["phone"],
                  size: "small",
                  label: ""
                } : {
                  size: "small",
                  label: ""
                }}
                component={TextField}
              />
            </Box>
            <Box style={{width: "50%"}}>
              <InputLabel>
                <Tooltip
                  title="Copy & paste your account URL in here"
                  style={{ cursor: "pointer", width: "fit-content" }}
                  placement="right-end"
                >
                  <Box>
                    <span>
                    LinkedIn Account
                    </span>
                    <span>
                      <InfoIcon style={{ fontSize: "small", marginLeft: "4px" }}/>
                    </span>
                  </Box>
                </Tooltip>
              </InputLabel>
              <TextField
                fullWidth
                required
                size="small"
                type="text"
                variant="outlined"
                value={basicValues.linkedin_account}
                onBlur={handleLinkedInAccount}
                onChange={handleLinkedInAccount}
                name="linkedin_account"
                {...(basicErrors["linkedin_account"] && {
                  error: true,
                  helperText: basicErrors["linkedin_account"],
                })}
              />
              {basicValues.linkedin_account && <Box>
                <Link
                  target="_blank"
                  href={`https://linkedin.com/in/${basicValues.linkedin_account}`}
                  style={{ fontSize: "14px"}}
                >
                  Click here to visit your profile
                </Link>
              </Box>}
            </Box>
          </Box>
          <Box>
            <Box style={{ width: "50%" }}>
              <InputLabel>
                <Tooltip
                  title="Provide your birthday to remind colleagues to send you warm wishes."
                  style={{ cursor: "pointer", width: "fit-content" }}
                  placement="right-end"
                >
                  <Box>
                    <span>
                      Birthday
                    </span>
                    <span>
                      <InfoIcon style={{ fontSize: "small", marginLeft: "4px" }} />
                    </span>
                  </Box>
                </Tooltip>
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                type="date"
                variant="outlined"
                value={basicValues.birthday}
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name="birthday"
                disabled={!!currentUser.birthday}
              />
              {!currentUser.birthday &&
                <p style={{ color: "red", fontSize: "12px", marginTop: "8px" }}>
                  Once saved, birthday cannot be changed.
                </p>
              }
            </Box>
          </Box>
          <Box>
            <InputLabel>Profile picture</InputLabel>
            {hasValidGravatar ?
              <Box style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
                <CircleIcon style={{ color: "green", marginRight: "8px" }}/>
                Your gravatar is setup 👍
              </Box>
              :
              <Box style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
                <CircleIcon style={{ color: "red", marginRight: "8px" }}/>
                Click <Link style={{ marginLeft: "4px", marginRight: "4px" }} target="_blank" href="https://gravatar.com">here</Link> to setup your gravatar
              </Box>
            }
          </Box>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="submit"
              size="small"
              variant="squared"
              color="primaryContained"
              disabled={loading}
              style={{ width: "200px" }}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Divider style={{ marginTop: "64px", marginBottom: "48px" }} />
        <h1>Change your password</h1>
        <Box
          style={{ maxWidth: "min(100%, 560px)", display: "flex", flexDirection: "column", rowGap: "32px" }}
          component="form"
          onSubmit={handlePasswordSubmit}
        >
          <Box style={{ display: "flex", columnGap: "16px"}}>
            <Box style={{ width: "50%" }}>
              <InputLabel>Current password</InputLabel>
              <TextField
                fullWidth
                required
                size="small"
                type="password"
                variant="outlined"
                onBlur={handlePasswordValue}
                onChange={handlePasswordValue}
                name="currentPassword"
                {...(passwordErrors["currentPassword"] && {
                  error: true,
                  helperText: passwordErrors["currentPassword"],
                })}
              />
            </Box>
            <Box style={{ width: "50%" }}>
              <InputLabel>New password</InputLabel>
              <TextField
                fullWidth
                required
                size="small"
                type="password"
                variant="outlined"
                onBlur={handlePasswordValue}
                onChange={handlePasswordValue}
                name="password"
                {...(passwordErrors["password"] && {
                  error: true,
                  helperText: passwordErrors["password"],
                })}
              />
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "end", paddingBottom: "64px" }}>
            <Button
              type="submit"
              size="small"
              variant="squared"
              color="primaryContained"
              disabled={loading}
              style={{ width: "200px" }}
            >
              Change password
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Profile;
