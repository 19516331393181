import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useSnackbar } from "notistack";
import slugify from "slugify";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Container,
  Typography
} from "@mui/material";
import { capitalize, formatSalaryInternal, getFormattedDate } from "bundles/common/utils/helpers";
import { statusChipColor } from "bundles/common/utils/constants";
import { archiveOffer, deleteOffer } from "bundles/common/utils/actions/job_offers";
import { updateCurrentUser } from "bundles/common/reducers/user";
import { duplicateOffer } from "bundles/common/utils/actions/job_offers";
import ConfirmModal from "bundles/Internal/components/ConfirmModal";
import NoData from "internal/no-data.svg";
import sh from "bundles/common/utils/sh";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { handleLinkedinShare } from "bundles/common/utils/helpers";
import CustomExpansionControl from "bundles/Internal/components/partials/CustomExpansionControl";
import ExpandedJobOffer from "bundles/Internal/components/partials/ExpandedJobOffer";

const Dashboard = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentUser = useSelector((state) => state.current_user);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [isArchiveConfirmModalOpen, setIsArchiveConfirmModalOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [dashboardStats, setDashboardStats] = useState(null);
  const jobOffers = currentUser.job_offers || [];

  const sortedJobOffers = React.useMemo(() => {
    return jobOffers
      .filter((offer) => {
        if (selectedStatus === "all") return true;
        return offer.offer_status?.toLowerCase() === selectedStatus;
      })
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  }, [jobOffers, selectedStatus]);

  const customStyles = {
    headCells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-last-of-type(2)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      }
    },
    cells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-last-of-type(2)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      }
    }
  };

  const columns = [
    {
      name: "",
      button: true,
      cell: (row) => (
        <CustomExpansionControl
          row={row}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          applicationsCount={row.job_applications.length}
          isRowExpandable={() => isRowExpandable(row)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      width: "56px",
    },
    {
      name: "Job title",
      selector: row => row.job_title,
      grow: 1.5,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.job_title < rowB.job_title) return -1;
        if (rowA.job_title > rowB.job_title) return 1;

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateB - dateA;
      },
      width: "9vw"
    },
    {
      name: "Company",
      selector: row => row.company?.name || "N/A",
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const nameA = rowA.company?.name?.toLowerCase() || "";
        const nameB = rowB.company?.name?.toLowerCase() || "";
        return nameA.localeCompare(nameB);
      },
      width: "7vw",
    },
    {
      name: "City",
      selector: row => row.city,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.city < rowB.city) return -1;
        if (rowA.city > rowB.city) return 1;

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateB - dateA;
      },
      width: "8vw"
    },
    {
      name: "Status",
      selector: row => row.job_status.charAt(0).toUpperCase() + row.job_status.slice(1),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.job_status < rowB.job_status) return -1;
        if (rowA.job_status > rowB.job_status) return 1;

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateB - dateA;
      }
    },
    {
      name: "Category",
      selector: row =>  row.category?.name ? <Chip label={row.category?.name} /> : "",
      grow: 1.5,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.category?.name < rowB.category?.name) return -1;
        if (rowA.category?.name > rowB.category?.name) return 1;

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateB - dateA;
      },
      width: "16vw"
    },
    {
      name: "Salary",
      selector: row => {
        if (row.salary_type === "no-salary") {
          return <span style={{ color: "grey" }}>No salary</span>;
        }
        if ((row.min_salary || row.min_salary === 0) && row.max_salary) {
          return formatSalaryInternal(row.salary_type, row.min_salary, row.max_salary);
        }
        return "/";
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const salaryTypePriority = { yearly: 3, monthly: 2, daily: 1, "no-salary": 0 };

        const typePriorityA = salaryTypePriority[rowA.salary_type] || 0;
        const typePriorityB = salaryTypePriority[rowB.salary_type] || 0;

        if (typePriorityA !== typePriorityB) {
          return typePriorityB - typePriorityA;
        }

        if (rowA.min_salary !== rowB.min_salary) {
          return rowB.min_salary - rowA.min_salary;
        }

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateB - dateA;
      },
      width: "10vw"
    },
    {
      name: "Status",
      selector: row =>  {
        let value = capitalize(row.offer_status);
        return <Chip color={statusChipColor[row.offer_status] || "default"} label={value}/>;
      },
      center: true,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.offer_status < rowB.offer_status) return -1;
        if (rowA.offer_status > rowB.offer_status) return 1;

        const dateA = new Date(rowA.published_at);
        const dateB = new Date(rowB.published_at);
        return dateA - dateB;
      },
      width: "8vw"
    },
    {
      name: "Publication date",
      selector: row => {
        const dateToShow = row.offer_status === "scheduled" ? row.scheduled_at : row.published_at;
        const formattedDate = getFormattedDate(dateToShow, false);
        return (row.offer_status === "scheduled" || row.offer_status === "archived") ?
          <span style={{ color: "grey" }}>{formattedDate}</span> :
          formattedDate;
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.offer_status === "scheduled" ? rowA.scheduled_at : rowA.published_at);
        const dateB = new Date(rowB.offer_status === "scheduled" ? rowB.scheduled_at : rowB.published_at);
        return dateA - dateB;
      },
      width: "9vw"
    },
    {
      name: "Share",
      selector: row => {
        if (row.offer_status !== "online") {
          return null;
        }

        const slugifyOptions = { lower: true, strict: true, remove: /[*+~.()'"!:@]/g };
        const jobTitleSlug = slugify(row.job_title, slugifyOptions);
        const jobUrl = `${window.location.origin}/jobs/${jobTitleSlug}/${row.id}`;

        return (
          <IconButton
            color="primary"
            onClick={() => handleLinkedinShare(jobUrl)}
          >
            <LinkedInIcon sx={{ fontSize: 28 }}/>
          </IconButton>
        );
      },
      center: true,
      width: "5vw",
    },
    {
      name: "Actions",
      selector: row => {
        return (<Box>
          {row.offer_status !== "archived" &&
            <Button
              size="small"
              variant="text"
              onClick={() => navigate(`edit_ad/${row.id}`)}
            >
              Edit
            </Button>
          }
          {(row.offer_status === "draft" || row.offer_status === "scheduled") &&
            <Button
              size="small"
              variant="text"
              disabled={loading}
              onClick={() => onDeleteClicked(row.id)}
            >
              Delete
            </Button>
          }
          {row.offer_status == "online" &&
            <Button
              size="small"
              variant="text"
              disabled={loading}
              onClick={() => onArchiveClicked(row.id)}
            >
              Archive
            </Button>
          }
          {row.offer_status == "archived" &&
            <Button
              size="small"
              variant="text"
              disabled={loading}
              onClick={() => duplicateOffer(row.id, setLoading, authenticityToken, onDuplicateSuccessful, onUpdateFail)}
            >
              Duplicate
            </Button>
          }
        </Box>);
      },
      width: "11vw"
    },
    {
      name: "See Job",
      selector: row => {
        if (row.offer_status !== "online") {
          return null;
        }

        const slugifyOptions = {
          lower: true,
          strict: true,
          remove: /[*+~.()'"!:@]/g
        };
        const jobTitleSlug = slugify(row.job_title, slugifyOptions);
        const publicURL = window.location.origin;
        const jobUrl = `${publicURL}/jobs/${jobTitleSlug}/${row.id}`;
        return (
          <IconButton
            size="small"
            onClick={() => window.open(jobUrl, "_blank")}
          >
            <ArrowForwardIcon />
          </IconButton>
        );
      },
      center: true,
      width: "5.5vw"
    },
  ];

  const onDeleteClicked = (offerId) => {
    setSelectedOfferId(offerId);
    setIsDeleteConfirmModalOpen(true);
  };

  const onDeleteConfirmed = () => {
    deleteOffer(selectedOfferId, setLoading, authenticityToken, onDeleteSuccessful, onUpdateFail);
  };

  const onArchiveClicked = (offerId) => {
    setSelectedOfferId(offerId);
    setIsArchiveConfirmModalOpen(true);
  };

  const onArchiveConfirmed = () => {
    archiveOffer(selectedOfferId, setLoading, authenticityToken, onArchiveSuccessful, onUpdateFail);
  };

  const onConfirmModalCloses = () => {
    setSelectedOfferId(null);
  };

  const onDuplicateSuccessful = () => {
    updateCurrentUser(dispatch, authenticityToken);
    enqueueSnackbar("Offer succesfully duplicated!", {
      variant: "success",
    });
  };

  const onArchiveSuccessful = () => {
    updateCurrentUser(dispatch, authenticityToken);
    enqueueSnackbar("Offer succesfully archived!", {
      variant: "success",
    });
  };

  const onDeleteSuccessful = () => {
    updateCurrentUser(dispatch, authenticityToken);
    enqueueSnackbar("Draft succesfully deleted!", {
      variant: "success",
    });
  };

  const onUpdateFail = () => {
    enqueueSnackbar(
      "Oops, an error occured.",
      { variant: "error" }
    );
  };

  const isRowExpandable = (row) => {
    return ["online", "archived"].includes(row.offer_status) && row.job_applications && row.job_applications.length > 0;
  };

  const fetchDashboardStats = () => {
    sh.get("/internal/dashboard_stats", {
      authenticity_token: authenticityToken,
    })
      .then((res) => {
        if (res?.data) {
          setDashboardStats(res.data);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch dashboard stats:", error);
      });
  };

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "24px", marginTop: "20px"}}>
          <h1 style={{fontSize: "24px"}}>Welcome to your dashboard, {currentUser.first_name}!</h1>
          <Button variant="squared" color="primaryContained" href="internal/new_ad">New job offer</Button>
        </Box>
        <Box>
          {dashboardStats && (
            <Box style={{ display: "flex", justifyContent: "space-around", margin: "28px" }}>
              <Box style={{ border: "1px solid #ccc", padding: "16px", borderRadius: "8px" }}>
                <Typography variant="h7">Online Job Offers</Typography>
                <Typography variant="h5" style={{ display: "flex", justifyContent: "center" }}>
                  {dashboardStats.online_job_offers_count}
                </Typography>
              </Box>
              <Box style={{ border: "1px solid #ccc", padding: "16px", borderRadius: "8px" }}>
                <Typography variant="h7">Published This Week</Typography>
                <Typography variant="h5" style={{ display: "flex", justifyContent: "center" }}>
                  {dashboardStats.created_this_week_count}
                </Typography>
              </Box>
              <Box style={{ border: "1px solid #ccc", padding: "16px", borderRadius: "8px" }}>
                <Typography variant="h7">Applications Received This Week</Typography>
                <Typography variant="h5" style={{ display: "flex", justifyContent: "center" }}>
                  {dashboardStats.job_applications_this_week_count}
                </Typography>
              </Box>
              <Box style={{ border: "1px solid #ccc", padding: "16px", borderRadius: "8px" }}>
                <Typography variant="h7">Planned Job Offers</Typography>
                <Typography variant="h5" style={{ display: "flex", justifyContent: "center" }}>
                  {dashboardStats.planned_job_offers}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        {currentUser.job_offers.length > 0 &&
          <Box display="flex" justifyContent="flex-start" marginTop={1} marginBottom={2}>
            <Button
              size="small"
              variant={selectedStatus === "all" ? "contained" : "outlined"}
              onClick={() => setSelectedStatus("all")}
              style={{ marginRight: "8px" }}
            >
              All
            </Button>
            <Button
              size="small"
              variant={selectedStatus === "online" ? "contained" : "outlined"}
              onClick={() => setSelectedStatus("online")}
              style={{ marginRight: "8px" }}
            >
              Online
            </Button>
            <Button
              size="small"
              variant={selectedStatus === "draft" ? "contained" : "outlined"}
              onClick={() => setSelectedStatus("draft")}
              style={{ marginRight: "8px" }}
            >
              Drafts
            </Button>
            <Button
              size="small"
              variant={selectedStatus === "scheduled" ? "contained" : "outlined"}
              onClick={() => setSelectedStatus("scheduled")}
              style={{ marginRight: "8px" }}
            >
              Scheduled
            </Button>
            <Button
              size="small"
              variant={selectedStatus === "archived" ? "contained" : "outlined"}
              onClick={() => setSelectedStatus("archived")}
            >
              Archived
            </Button>
          </Box>
        }
        <DataTable
          noDataComponent={
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", rowGap: "16px" }}>
              {selectedStatus === "all" ? (
                <h3>No jobs created yet</h3>
              ) : (
                <h3>No {selectedStatus} jobs</h3>
              )}
              <Button variant="squared" color="primaryContained" href="internal/new_ad">Click here to create one</Button>
              <img style={{ width: "45%", marginTop: "64px" }} src={NoData} />
            </Box>
          }
          columns={columns}
          data={sortedJobOffers}
          defaultSortAsc={false}
          pointerOnHover
          responsive
          striped
          expandableRows
          expandableRowExpanded={row => expandedRows.includes(row.id)}
          expandableRowsComponent={({ data }) => <ExpandedJobOffer data={data} />}
          expandableRowsHideExpander={true}
          highlightOnHover
          pagination
          customStyles={customStyles}
        />
      </Container>
      <ConfirmModal
        isOpen={isDeleteConfirmModalOpen}
        setIsOpen={setIsDeleteConfirmModalOpen}
        handleConfirm={onDeleteConfirmed}
        label="You're about to delete this offer."
        onCloseCallback={onConfirmModalCloses}
      />
      <ConfirmModal
        isOpen={isArchiveConfirmModalOpen}
        setIsOpen={setIsArchiveConfirmModalOpen}
        handleConfirm={onArchiveConfirmed}
        label="You're about to archive this offer."
        onCloseCallback={onConfirmModalCloses}
      />
    </>
  );
};

export default Dashboard;
