import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container, Box, Chip, Card, Typography, Switch, Avatar, Checkbox, MenuItem, ListItemText, FormControl, InputLabel, Select, TextField, RadioGroup, FormControlLabel, Radio, FormLabel } from "@mui/material";
import DataTable from "react-data-table-component";
import sh from "bundles/common/utils/sh";
import { capitalize } from "bundles/common/utils/helpers";
import { useSnackbar } from "notistack";
import ConfirmModal from "./ConfirmModal";
import { userRoles, offices } from "bundles/common/utils/constants";

const Employees = () => {
  const currentUser = useSelector((state) => state.current_user);
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [regionalDepartments, setRegionalDepartments] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const customStyles = {
    table: {
      style: {
        border: "1px solid #DDDDDD",
      },
    },
    headRow: {
      style: {
        borderBottom: "1px solid #DDDDDD",
      },
    },
    headCells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-last-of-type(1)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
        "&:nth-last-of-type(2)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      },
    },
    cells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-last-of-type(1)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
        "&:nth-last-of-type(2)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      },
    },
    rows: {
      style: {
        minHeight: "60px"
      },
    },
  };

  const columns = [
    {
      name: "Name",
      selector: row => {
        return (
          <Box style={{ display: "flex", columnGap: "4px", alignItems: "center" }}>
            <Avatar
              sx={{ width: 24, height: 24 }}
              alt={`${row.first_name} ${row.last_name}`}
              src={row.avatar_url}
            />
            <Box>{`${row.first_name} ${row.last_name}`}</Box>
          </Box>
        );
      },
      sortable: true,
      grow: 1,
    },
    {
      name: "Email",
      selector: row => row.email,
      grow: 2,
    },
    {
      name: "Manager",
      selector: row => row.manager ? `${row.manager.first_name}` : "/",
      grow: 1,
    },
    {
      name: "Regional Departments",
      selector: (row) => row.regional_departments.map((department) => department.name).join(", "),
      grow: 2,
    },
    {
      name: "Role",
      selector: row => capitalize(row.role),
    },
    {
      name: "Joined on",
      selector: row => {
        if (row.pending_invitation) {
          return (<Chip label="Resend invitation" disabled={loading} color="warning" onClick={() => resendInvitation(row.id)} />);
        }
        else {
          return row.invitation_accepted_at ? new Date(row.invitation_accepted_at).toLocaleDateString() : "Not Accepted";
        }
      },
      width: "160px"
    },
  ];

  const resendInvitation = (candidateId) => {
    setLoading(true);
    sh.post("/internal/resend_invitation", {
      authenticity_token: authenticityToken,
      user: { id: candidateId },
    })
      .then(() => {
        setLoading(false);
        enqueueSnackbar("Invitation sent again!", {
          variant: "success",
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const navigateToInvitation = () => {
    navigate("/internal/invitation");
  };

  const redirectUnauthorizedUsed = () => {
    navigate("/internal");
  };

  const fetchAllUsers = () => {
    sh.get("internal/users").then((res) => {
      setUsers(res?.data?.users || []);
    });
  };

  const fetchAllRegionalDepartments = () => {
    sh.get("internal/all_regional_departments").then((res) => {
      setRegionalDepartments(res?.data?.regional_departments || []);
    });
  };

  const deleteUser = () => {
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    setLoading(true);
    sh.delete("internal/users", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token: authenticityToken,
        user: { id: currentRow?.id }
      },
    }).then(() => {
      fetchAllUsers();
      enqueueSnackbar("User succesfully deleted!", {
        variant: "success",
      });
    }).catch(() => {
      enqueueSnackbar(
        "Oops, an error occured.",
        { variant: "error" }
      );
    }).finally(() => {
      setLoading(false);
    });
  };

  const ExpandedComponent = ({ data }) => {
    const user = data;
    const [userRole, setUserRole] = useState(user.role);

    const [userRegionalDepartments, setUserRegionalDepartments] = useState(
      user.regional_departments || []
    );

    const [userMainOffice, setUserMainOffice] = useState(
      user.main_office || []
    );

    const [emailNotifications, setEmailNotifications] = useState(data.email_notifications || {});

    const [isDirty, setIsDirty] = useState(user.role !== userRole || !arraysEqual(userRegionalDepartments, user.regional_departments));

    function arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    }

    const handleRegionalDepartmentsChange = (latestSelectedId) => {
      const isAlreadySelected = userRegionalDepartments.some(department => department.id === latestSelectedId);
      let newSelection;

      if (isAlreadySelected) {
        if (userRegionalDepartments.length > 1) {
          newSelection = userRegionalDepartments.filter(department => department.id !== latestSelectedId);
        } else {
          newSelection = [...userRegionalDepartments];
        }
      } else {
        const newDepartment = regionalDepartments.find(department => department.id === latestSelectedId);
        newSelection = newDepartment ? [...userRegionalDepartments, newDepartment] : [...userRegionalDepartments];
      }

      setUserRegionalDepartments(newSelection);
    };

    const handleGlobalEmailChange = (field) => (event) => {
      setEmailNotifications((prev) => ({
        ...prev,
        global: {
          ...prev.global,
          [field]: event.target.checked,
        },
      }));
    };

    const handlePrivateEmailChange = (type, selectedUsers) => {
      setEmailNotifications((prev) => ({
        ...prev,
        private: {
          ...prev.private,
          [type]: selectedUsers.map((user) => user.id),
        },
      }));
    };

    const updateUser = (values) => {
      setLoading(true);
      sh.put("internal/users", {
        authenticity_token: authenticityToken,
        user: values
      }).then(() => {
        fetchAllUsers();
        enqueueSnackbar("User succesfully edited!", {
          variant: "success",
        });
      }).catch(() => {
        enqueueSnackbar(
          "Oops, an error occured.",
          { variant: "error" }
        );
      }).finally(() => {
        setLoading(false);
      });
    };

    useEffect(() => {
      const emailNotificationsChanged = JSON.stringify(data.email_notifications) !== JSON.stringify(emailNotifications);
      const roleChanged = data.role !== userRole;
      const departmentsChanged = !arraysEqual(userRegionalDepartments, data.regional_departments);
      const userOfficeChanged = data.main_office !== userMainOffice;

      if (emailNotificationsChanged || roleChanged || departmentsChanged || userOfficeChanged) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }, [emailNotifications, userRole, userRegionalDepartments, userMainOffice, data]);


    return (
      <Box style={{ padding: "16px 32px"}}>
        <Card style={{ padding: "16px 32px" }}>
          <Box style={{  }}>
            <Box style={{ display: "flex", gap: "16px", flexWrap: "wrap", marginBottom: "16px" }}>
              <TextField
                select
                style={{ width: "180px" }}
                size="small"
                label="Role"
                name="role"
                value={userRole}
                disabled={user?.id == currentUser.id}
                onChange={(e) => setUserRole(e.target.value)}
              >
                {userRoles.map((role) => {
                  return (
                    <MenuItem value={role.code} key={role.code}>
                      {role.label}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => loading ? "" : `${selected.length} selected`
                }}
                style={{ width: "180px", marginLeft: "16px" }}
                size="small"
                label="Regional departments"
                name="regional_department_ids"
                value={userRegionalDepartments}
                disabled={user?.id === currentUser.id}
              >
                {regionalDepartments.map((regionalDepartment) => (
                  <MenuItem
                    key={regionalDepartment.id}
                    value={regionalDepartment}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={userRegionalDepartments.some(department => department.id === regionalDepartment.id)}
                          onClick={() => handleRegionalDepartmentsChange(regionalDepartment.id)}
                        />
                      }
                      label={regionalDepartment.name}
                    />
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                style={{ width: "180px", marginLeft: "16px" }}
                size="small"
                label="Main Office"
                name="main_office"
                value={userMainOffice}
                onChange={(e) => setUserMainOffice(e.target.value)}
              >
                {offices.map((office) => {
                  return (
                    <MenuItem value={office.code} key={office.code}>
                      {office.label}
                    </MenuItem>
                  );
                })}
              </TextField>
              <FormControlLabel
                control={
                  <Switch
                    checked={emailNotifications.global?.daily_metrics || false}
                    onChange={handleGlobalEmailChange("daily_metrics")}
                  />
                }
                style={{ width: "fit-content", marginLeft: "16px" }}
                label="Daily Metrics 📩"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={emailNotifications.global?.finance || false}
                    onChange={handleGlobalEmailChange("finance")}
                  />
                }
                style={{ width: "fit-content", marginLeft: "16px" }}
                label="Finance* 📩"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={emailNotifications.global?.it || false}
                    onChange={handleGlobalEmailChange("it")}
                  />
                }
                style={{ width: "fit-content", marginLeft: "16px" }}
                label="IT* 📩"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={emailNotifications.global?.hr || false}
                    onChange={handleGlobalEmailChange("hr")}
                  />
                }
                style={{ width:"fit-content", marginLeft: "16px" }}
                label="HR* 📩"
              />
            </Box>
            <Box style={{ display: "flex", gap: "16px", flexWrap: "wrap", marginBottom: "16px" }}>
              {/* Private Emails: Job Application */}
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => selected.length ? `${selected.length} selected` : "Select users"
                }}
                style={{ width: "180px" }}
                size="small"
                label="Job Applications Recipients"
                value={users.filter(user => emailNotifications.private?.job_applications?.includes(user.id))}
                onChange={(event) => handlePrivateEmailChange("job_applications", event.target.value)}
              >
                <MenuItem key={user.id} value={user} disabled>
                  <FormControlLabel
                    control={<Radio checked />}
                    label={`${user.first_name} ${user.last_name}`}
                  />
                </MenuItem>

                {users
                  .filter(u => u.id !== user.id)
                  .map((u) => (
                    <MenuItem key={u.id} value={u}>
                      <FormControlLabel
                        control={<Radio checked={emailNotifications.private?.job_applications?.includes(u.id)} />}
                        label={`${u.first_name} ${u.last_name}`}
                      />
                    </MenuItem>
                  ))}
              </TextField>

              {/* Private Emails: Job Offer Expired */}
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => selected.length ? `${selected.length} selected` : "Select users"
                }}
                style={{ width: "180px", marginLeft: "16px" }}
                size="small"
                label="Job Offer Expired Recipients"
                value={users.filter(user => emailNotifications.private?.job_offers_expired?.includes(user.id))}
                onChange={(event) => handlePrivateEmailChange("job_offers_expired", event.target.value)}
              >
                <MenuItem key={user.id} value={user} disabled>
                  <FormControlLabel
                    control={<Radio checked />}
                    label={`${user.first_name} ${user.last_name}`}
                  />
                </MenuItem>

                {users
                  .filter(u => u.id !== user.id)
                  .map((u) => (
                    <MenuItem key={u.id} value={u}>
                      <FormControlLabel
                        control={<Radio checked={emailNotifications.private?.job_offers_expired?.includes(u.id)} />}
                        label={`${u.first_name} ${u.last_name}`}
                      />
                    </MenuItem>
                  ))}
              </TextField>

              {/* Private Emails: Reminders */}
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => selected.length ? `${selected.length} selected` : "Select users"
                }}
                style={{ width: "180px", marginLeft: "16px" }}
                size="small"
                label="Reminders Recipients"
                value={users.filter(user => emailNotifications.private?.reminders?.includes(user.id))}
                onChange={(event) => handlePrivateEmailChange("reminders", event.target.value)}
              >
                <MenuItem key={user.id} value={user}>
                  <FormControlLabel
                    control={<Radio checked={emailNotifications.private?.reminders?.includes(user.id)} />}
                    label={`${user.first_name} ${user.last_name}`}
                  />
                </MenuItem>

                {users
                  .filter(u => u.id !== user.id)
                  .map((u) => (
                    <MenuItem key={u.id} value={u}>
                      <FormControlLabel
                        control={<Radio checked={emailNotifications.private?.reminders?.includes(u.id)} />}
                        label={`${u.first_name} ${u.last_name}`}
                      />
                    </MenuItem>
                  ))}
              </TextField>

              {/* Private Emails: Weekly Metrics */}
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => selected.length ? `${selected.length} selected` : "Select users"
                }}
                style={{ width: "180px", marginLeft: "16px" }}
                size="small"
                label="Weekly Metrics Recipients"
                value={users.filter(user => emailNotifications.private?.weekly_metrics?.includes(user.id))}
                onChange={(event) => handlePrivateEmailChange("weekly_metrics", event.target.value)}
              >
                <MenuItem key={user.id} value={user} disabled>
                  <FormControlLabel
                    control={<Radio checked />}
                    label={`${user.first_name} ${user.last_name}`}
                  />
                </MenuItem>

                {users
                  .filter(u => u.id !== user.id)
                  .map((u) => (
                    <MenuItem key={u.id} value={u}>
                      <FormControlLabel
                        control={<Radio checked={emailNotifications.private?.weekly_metrics?.includes(u.id)} />}
                        label={`${u.first_name} ${u.last_name}`}
                      />
                    </MenuItem>
                  ))}
              </TextField>
              <FormControlLabel
                control={
                  <Switch
                    checked={emailNotifications.global?.birthdays || false}
                    onChange={handleGlobalEmailChange("birthdays")}
                  />
                }
                style={{ width: "fit-content", marginLeft: "16px" }}
                label="Birthdays 🎂"
              />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              {/* Buttons aligned to the left */}
              <Box display="flex">
                <Button
                  size="small"
                  variant="squared"
                  color="primaryContained"
                  style={{ marginRight: "16px" }}
                  disabled={loading || !isDirty}
                  onClick={() => updateUser({
                    id: user.id,
                    role: userRole,
                    regional_department_ids: userRegionalDepartments.map(department => department.id),
                    main_office: userMainOffice,
                    email_notifications: emailNotifications
                  })}
                >
                  Save
                </Button>

                <Button
                  variant="squared"
                  color="dangerContained"
                  size="small"
                  disabled={user?.id == currentUser.id || loading}
                  onClick={() => deleteUser(user?.id)}
                >
                  Delete
                </Button>
              </Box>

              <Typography variant="body2" color="textSecondary">
                *Contact Forms, Spontaneous Applications & Referrals
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  };

  useEffect(() => {
    if (!currentUser || !currentUser["admin?"]) {
      redirectUnauthorizedUsed();
    } else {
      fetchAllUsers();
      fetchAllRegionalDepartments();
    }
  }, []);

  return (
    <Container maxWidth="xl" style={{ paddingTop: "16px" }}>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", margin: "32px 64px" }}>
        <Typography gutterBottom sx={{ fontSize: "28px"}}>
          Employees
        </Typography>
        <Button variant="squared" color="primaryOutlined" onClick={navigateToInvitation}>Invite a new teammate</Button>
      </Box>
      <DataTable
        columns={columns}
        data={users}
        responsive
        striped
        highlightOnHover
        pointerOnHover
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={ExpandedComponent}
        expandableRowExpanded={(row) => (row === currentRow)}
        onRowClicked={(row) => setCurrentRow(row)}
        onRowExpandToggled={(bool, row) => setCurrentRow(row)}
        customStyles={customStyles}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        handleConfirm={handleDeleteConfirmed}
        label="You're about to delete this user."
      />
    </Container>
  );
};

export default Employees;
