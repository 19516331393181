import React from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { getDepartmentBgColorClass, formatSalaryPublic } from "bundles/common/utils/helpers";

const CareerDetails = ({ jobOffer }) => {
  const intl = useIntl();
  const location = useLocation();
  const currentUrl = `https://www.wearesander.com${location.pathname}`;

  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${currentUrl}`;
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;

  const whatsappMessage = intl.formatMessage(
    { id: "career_details.whatsapp_message" },
    { jobTitle: jobOffer?.job_title }
  );
  const encodedWhatsappMessage = encodeURIComponent(whatsappMessage);

  return (
    <section id="career-details" className="relative text-darkGreen" style={{ overflowX: "unset" }}>
      <div id="career-details-container" className="gg-container vw-pt [--pt:70] mini:[--pt:90]">
        <div id="career-details-grid" className="gg-grid !gap-y-0">
          <div id="career-details-content" className="col-start-1 col-span-12 mini:col-start-1 mini:col-span-14 tablet:col-span-15 laptop:col-start-2 laptop:col-span-14 flex flex-col vw-gap [--gap:48] tablet:[--gap:64] vw-mb [--mb:70]">
            <div id="career-details-heading" className="flex flex-col vw-gap [--gap:16] justify-start items-start">
              <div id="career-details-tag" className={`inline-block vw-text [--text:16] vw-leading [--leading:13] vw-p [--p:12] ${getDepartmentBgColorClass(jobOffer?.category?.department?.code)}`}>
                {jobOffer?.category?.name}
              </div>
              <div id="career-details-title" className="h1">
                {jobOffer?.job_title}
              </div>
            </div>
            <div id="career-details-lead" className="font-extralight vw-text [--text:20] leading-[160%] text-deepBlue"
              dangerouslySetInnerHTML={{ __html: jobOffer?.summary }}>
            </div>
            <div id="careers-details-richtext" className="richtext small-paragraph"
              dangerouslySetInnerHTML={{ __html: jobOffer?.description }}>
            </div>
            <hr className="border-darkGreen/30" />
            <div id="careers-details-richtext" className="richtext small-paragraph tablet:vw-pl tablet:[--pl:36] laptop:[--pl:56]">
              <p className="category-title">{jobOffer?.role_title}</p>
              <div dangerouslySetInnerHTML={{ __html: jobOffer?.role }}/>
            </div>
            <div id="careers-details-richtext" className="richtext small-paragraph tablet:vw-pl tablet:[--pl:36] laptop:[--pl:56]">
              <p className="category-title">{jobOffer?.profile_title}</p>
              <div dangerouslySetInnerHTML={{ __html: jobOffer?.profile }} />
            </div>
            <div id="careers-details-richtext" className="richtext small-paragraph tablet:vw-pl tablet:[--pl:36] laptop:[--pl:56]">
              <p className="category-title">{jobOffer?.offer_title}</p>
              <div dangerouslySetInnerHTML={{ __html: jobOffer?.offer }} />
            </div>
          </div>
          <div id="career-details-benefits" className="bg-lightBeige col-start-2 col-span-10 mini:col-start-1 mini:col-span-14 tablet:col-span-15 laptop:col-span-16 flex flex-col vw-gap [--gap:30] vw-py [--py:60] mini:[--py:70] max-tablet:shadow-lightBeige max-tablet:shadow-[0_0_0_100vmax] max-tablet:[clip-path:inset(0_-100vmax)] tablet:vw-px tablet:[--px:64] laptop:[--px:48]">
            <div id="careers-detail-benefits-title" className="category-title fadeInUp"><FormattedMessage id="benefits.title" defaultMessage="Benefits" /></div>
            <ul id="career-details-benefits-features" className="flex flex-col vw-gap [--gap:24] mini:vw-gap-x mini:[--gap-x:36] tablet:[--gap-x:60] laptop:[--gap-x:72] mini:grid-cols-2 laptop:grid-cols-3 mini:grid [&_li]:flex [&_li]:items-center [&_li]:justify-start [&_li]:vw-gap [&_li]:[--gap:16] [&_svg]:aspet-square [&_svg]:vw-w [&_svg]:[--w:24] [&_svg]:object-contain [&_svg]:text-deepBlue">
              {jobOffer?.benefits.map((benefit, index) => (
                <li key={index} className="body-paragraph fadeInUp">
                  <img src={`/static/images/benefits/${benefit}.svg`} alt={benefit} />
                  <span><FormattedMessage id={`benefits.${benefit}`} defaultMessage={benefit}/></span>
                </li>
              ))}
            </ul>
          </div>
          <aside id="career-details-sidebar" className="vw-py [--py:60]  mini:[--py:95] tablet:vw-pt tablet:[--pt:95] tablet:vw-pb tablet:[--pb:180] max-mini:col-start-2 max-mini:col-span-10 bg-lightBlue max-mini:shadow-lightBlue max-mini:shadow-[0_0_0_100vmax] max-mini:[clip-path:inset(0_-100vmax)] z-10 mini:absolute top-0 right-0 mini:vw-pl [--pl:28] mini:vw-pr mini:[--pr:52] tablet:[--pr:84] laptop:[--pl:48] desktop:[--pl:56] laptop:[--pr:60] desktop:[--pr:92] mini:vw-rounded-bl mini:[--rounded-bl:120] mini:h-[calc(100%-((288/var(--current-screen-size))*var(--screen-w)))] tablet:vw-w tablet:[--w:364] laptop:[--w:384] desktop:[--w:472] tablet:h-full">
            <div id="career-details-wrapper" className="flex flex-col vw-gap-y [--gap-y:48] mini:sticky mini:vw-top mini:[--top:95] tablet:[--top:70]">
              <div id="career-details-sidebar-label" className="flex justify-between items-center fadeInUp">
                <span className="subtitle uppercase text-deepBlue">
                  <FormattedMessage id="careers_details.details" defaultMessage="Details" />
                </span>
                {/* <time
                  className="vw-text [--text:10] font-light text-deepBlue"
                  dateTime={jobOffer?.published_at}
                  aria-label={`Posted on ${new Date(jobOffer?.published_at).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}`}>
                  Posted on <strong className="vw-ml [--ml:8] font-medium">{new Date(jobOffer?.published_at).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</strong>
                </time> */}
              </div>
              <div id="career-details-items" className="flex flex-col vw-gap [--gap:24] vw-text [--text:14] vw-leading [--leading:18] font-light">
                <div id="career-details-item" className="flex flex-col vw-gap [--gap:4] fadeInUp">
                  <strong className="font-semibold text-darkGreen"><FormattedMessage id="career_details.category"/></strong>
                  <span>{jobOffer?.category?.name}</span>
                </div>
                <div id="career-details-item" className="flex flex-col vw-gap [--gap:4] fadeInUp">
                  <strong className="font-semibold text-darkGreen"><FormattedMessage id="career_details.location" /></strong>
                  <span>{jobOffer?.city}, <FormattedMessage id={`career_details.regions.${jobOffer?.region}`} /></span>
                </div>
                <div id="career-details-item" className="flex flex-col vw-gap [--gap:4] fadeInUp">
                  <strong className="font-semibold text-darkGreen"><FormattedMessage id="career_details.type_of_contract"/></strong>
                  <span><FormattedMessage id={`career_details.${jobOffer?.job_status}`} /></span>
                </div>
                {jobOffer?.salary_type !== "no-salary" &&
                  <div id="career-details-item" className="flex flex-col vw-gap [--gap:4] fadeInUp">
                    <strong className="font-semibold text-darkGreen"><FormattedMessage id="career_details.salary" /></strong>
                    <span>
                      {formatSalaryPublic(jobOffer?.salary_type, jobOffer?.min_salary, jobOffer?.max_salary)}
                    </span>
                  </div>
                }
              </div>
              <hr className="border-darkGreen/30 fadeInUp" />
              <div id="career-details-button" className="fadeInUp">
                <button
                  data-side-form-action="open"
                  className="inline-flex text-center justify-center items-center w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full bg-deepBlue text-white"
                >
                  <svg className="vw-w [--w:7] vw-h [--h:13] object-contain overflow-visible" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999996 1.09796L6.40204 6.5L0.999997 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <FormattedMessage id="career_details.cta.apply_now" />
                </button>
              </div>
              <div id="career-details-manager" className="flex vw-gap [--gap:16] items-start justify-start fadeInUp">
                <div id="career-details-manager-image" className="aspect-square overflow-x-hidden vw-rounded [--rounded:6] vw-w [--w:64] tablet:[--w:91] shrink-0">
                  <img className="w-full h-full object-cover" src={jobOffer?.user?.avatar_url} alt="" loading="lazy" />
                </div>
                <div id="career-details-manager-data">
                  <small id="career-details-manager-label" className="block font-light vw-text [--text:10] leading-[normal] vw-mb [--mb:6]"><FormattedMessage id="career_details.managed_by" /></small>
                  <strong id="career-details-manager-name" className="font-medium block vw-text [--text:16] leading-[normal] vw-mb [--mb:20]">{jobOffer?.user?.first_name}</strong>
                  {jobOffer?.user?.phone &&
                    <a
                      href={`https://wa.me/${jobOffer?.user?.phone}?text=${encodedWhatsappMessage}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-start vw-gap [--gap:6] vw-mb [--mb:12]"
                    >
                      <svg className="vw-w [--w:15] vw-h [--h:12]" viewBox="0 0 308 308" xmlns="http://www.w3.org/2000/svg">
                        <path d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"></path>
                        <path d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"></path>
                      </svg>
                      <span className="font-light vw-text [--text:12] vw-leading [--leading:6]"><FormattedMessage id="career_details.chat_on_whatsapp" /></span>
                    </a>
                  }
                  <div id="career-details-manager-tel" className="flex items-center justify-start vw-gap [--gap:6] vw-mb [--mb:12]">
                    <svg className="vw-w [--w:15] vw-h [--h:10]" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.91724 6C2.22018 5.99782 2.51499 5.88717 2.76061 5.68347C3.00623 5.47977 3.19044 5.19315 3.28758 4.86353C3.58992 3.85633 4.15818 2.98253 4.91323 2.36382C5.66828 1.74511 6.57251 1.41231 7.49998 1.41176C8.42745 1.41231 9.33168 1.74511 10.0867 2.36382C10.8418 2.98253 11.41 3.85633 11.7124 4.86353C11.8095 5.19315 11.9937 5.47977 12.2393 5.68347C12.485 5.88717 12.7798 5.99782 13.0827 6H15C14.6927 2.64 11.4512 0 7.49998 0C3.54874 0 0.310323 2.64 0 6H1.91724Z" fill="#002830" />
                      <path d="M7.62451 2.60156C6.58147 2.60156 5.58114 3.01591 4.8436 3.75346C4.10606 4.491 3.69171 5.49133 3.69171 6.53438V9.32012H11.5573V6.53438C11.5573 5.49133 11.143 4.491 10.4054 3.75346C9.66789 3.01591 8.66756 2.60156 7.62451 2.60156ZM7.62451 7.76338C7.14645 7.76338 6.68797 7.57347 6.34993 7.23543C6.01189 6.89739 5.82198 6.4389 5.82198 5.96084C5.82198 5.48278 6.01189 5.0243 6.34993 4.68625C6.68797 4.34821 7.14645 4.1583 7.62451 4.1583C8.10258 4.1583 8.56106 4.34821 8.8991 4.68625C9.23714 5.0243 9.42705 5.48278 9.42705 5.96084C9.42705 6.4389 9.23714 6.89739 8.8991 7.23543C8.56106 7.57347 8.10258 7.76338 7.62451 7.76338Z" fill="#002830" />
                    </svg>
                    <span className="font-light vw-text [--text:10] vw-leading [--leading:6]">{jobOffer?.user?.office_phone}</span>
                  </div>
                  <a href={`mailto:${jobOffer?.user?.email}`} id="career-details-manager-email" className="flex items-center justify-start vw-gap [--gap:6] vw-mb [--mb:12]">
                    <svg className="vw-w [--w:15] vw-h [--h:9]" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.33338 4.36291L6.10352e-05 1.40625V7.90625H11V1.40625L6.66671 4.36291C6.32339 4.59883 5.91661 4.72513 5.50005 4.72513C5.08348 4.72513 4.6767 4.59883 4.33338 4.36291Z" fill="#002830" /><path d="M6.10352e-05 0.113281L4.33338 3.06995C4.6767 3.30587 5.08348 3.43216 5.50005 3.43216C5.91661 3.43216 6.32339 3.30587 6.66671 3.06995L11 0.113281H6.10352e-05Z" fill="#002830" /></svg>
                    <span className="font-light vw-text [--text:10] vw-leading [--leading:6]">{jobOffer?.user?.email}</span>
                  </a>
                </div>
              </div>
              <div id="career-details-share" className="flex flex-col items-center justify-center mini:items-start mini:justify-start vw-gap [--gap:24] fadeInUp">
                <div id="career-details-share-label" className="subtitle uppercase text-center mini:text-left">
                  <FormattedMessage id="career_details.share" />
                </div>
                <ul id="career-details-share-links" className="flex items-center justify-center mini:items-start mini:justify-start vw-gap [--gap:24]">
                  <li>
                    <a href={twitterShareUrl} aria-label="Share on Twitter" target="_blank" rel="noopener noreferrer">
                      <svg className="vw-w [--w:18] aspect-square" viewBox="0 0 124 123" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M73.3968 52.0879L119.194 0H108.345L68.5629 45.218L36.8125 0H0.183105L48.2064 68.3841L0.183105 123H11.0317L53.0157 75.2377L86.5537 123H123.183M14.9472 8.0047H31.6137L108.337 115.388H91.6664" fill="#346DE1" /></svg>
                    </a>
                  </li>
                  <li>
                    <a href={linkedInShareUrl} aria-label="Share on LinkedIn" target="_blank" rel="noopener noreferrer">
                      <svg className="vw-w [--w:18] aspect-square" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.19288 13.8058H1.17085V4.91333H4.19288V13.8058ZM2.60852 3.79614H2.58895C1.49359 3.79614 0.789429 3.12041 0.789429 2.25549C0.789429 1.39057 1.52293 0.714844 2.62808 0.714844C3.73322 0.714844 4.42761 1.38156 4.44717 2.25549C4.44717 3.12041 3.74301 3.79614 2.60852 3.79614ZM16.2712 13.8058H12.8482V9.20191C12.8482 7.99462 12.3103 7.17475 11.1367 7.17475C10.237 7.17475 9.73817 7.73335 9.50345 8.26491C9.41543 8.45412 9.42521 8.7244 9.42521 8.99469V13.8058H6.03154C6.03154 13.8058 6.08044 5.65212 6.03154 4.91333H9.42521V6.30982C9.62081 5.69717 10.7064 4.83224 12.4375 4.83224C14.5793 4.83224 16.2615 6.11161 16.2615 8.86856V13.8148L16.2712 13.8058Z" fill="#346DE1" /></svg>
                    </a>
                  </li>
                  <li>
                    <a href={facebookShareUrl} aria-label="Share on Facebook" target="_blank" rel="noopener noreferrer">
                      <svg className="vw-w [--w:18] aspect-square" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.73505 8.26044H7.13928L7.41669 5.35532H5.02087V4.33534C5.02087 3.64322 4.95361 3.42465 5.62613 3.42465H7.40829V0.519531H5.62613C3.28074 0.519531 2.0534 1.93111 2.0534 4.08946V5.36443H0.27124V8.26955H2.0534V16.0105H4.73505V8.26955V8.26044Z" fill="#346DE1" /></svg>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:?subject=Check this job offer&body=Hi, I found this job offer on WeAreSander.com and I thought you might be interested: https://wearesander.com/jobs/ux-designer" aria-label="Share by email">
                      <svg className="vw-w [--w:18] aspect-square" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5411 17.6266C3.50355 17.6266 0.416718 15.5902 0.416718 10.4669C0.416718 5.42932 3.5893 0.90625 9.89159 0.90625C15.2936 0.90625 17.5015 4.01452 17.5015 7.6587C17.5015 12.0746 15.465 13.8753 13.107 13.8753C10.9848 13.8753 10.2774 12.8463 10.2774 11.4315C10.2774 11.2815 10.2774 11.11 10.2989 10.9385H10.0845C9.37712 12.8249 8.47679 13.8753 7.08343 13.8753C5.69006 13.8753 4.70399 12.9535 4.70399 10.8742C4.70399 7.8302 5.99017 5.04347 8.39105 5.04347C9.76297 5.04347 10.3632 5.96523 10.5347 7.50865H10.6633L10.9634 5.27927H13.3428L12.6354 10.5741C12.5283 11.4529 12.8069 11.8388 13.45 11.8388C14.3504 11.8388 15.1864 10.7455 15.1864 7.87307C15.1864 5.21496 13.793 3.13563 9.54861 3.13563C5.13272 3.13563 2.92477 6.56545 2.92477 10.4454C2.92477 14.1325 5.04697 15.5259 8.58397 15.5259C9.97734 15.5259 11.8637 15.2043 12.9356 14.6898H13.0856V16.662C11.8637 17.2408 10.3846 17.6266 8.5411 17.6266ZM10.4489 8.92345C10.6419 7.76589 10.0845 7.07992 9.14132 7.07992C7.81226 7.07992 7.21205 8.62334 7.21205 10.4454C7.21205 11.3243 7.49072 11.8388 8.19812 11.8388C9.33425 11.8388 10.1917 10.5097 10.3846 9.30931L10.4489 8.92345Z" fill="#346DE1" /></svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
        </div>
      </div>
      <div id="career-details-container" className="gg-container relative overflow-x-clip overflow-y-visible">
        <div id="career-details-grid" className="gg-grid !gap-y-0">
          <div id="career-details-cta" className="col-span-12 mini:col-span-24 tablet:col-span-15 laptop:col-span-16 vw-py [--py:110] mini:[--py:90] tablet:[--py:100] laptop:[--py:140] desktop:[--py:165]">
            <svg id="career-details-cta-decoration-1" className="absolute -z-10 vw-top vw-left [--top:-30] mini:[--top:-120] laptop:[--top:-170] [--left:-124] tablet:[--left:-50] laptop:[--left:-90] desktop:[--left:-120] vw-w vw-h [--w:237] [--h:228] mini:[--w:308] mini:[--h:320] laptop:[--w:430] laptop:[--h:447] desktop:[--w:510] desktop:[--h:530]" viewBox="0 0 308 320" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" d="M148 0.499993C236.089 0.499997 307.5 71.9106 307.5 160C307.5 248.089 236.089 319.5 148 319.5L0.499986 319.5L0.5 0.499987L148 0.499993Z" stroke="#346DE1" /></svg>
            <svg id="career-details-cta-decoration-2" className="absolute -z-10 vw-bottom vw-left [--bottom:0] [--left:175] mini:[--left:548] tablet:[--left:518] laptop:[--left:542] desktop:[--left:664] vw-w vw-h [--w:228] [--h:140] mini:[--w:307] mini:[--h:190] laptop:[--w:429] laptop:[--h:265] desktop:[--w:509] desktop:[--h:314]" viewBox="0 0 308 191" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" d="M307 37C307 121.5 238.5 190 154 190C69.5005 190 1.00001 121.5 1 37L1 1.00003L307 1V37Z" stroke="#346DE1" /></svg>
            <div id="career-details-cta-title" className="h3 text-deepBlue text-center fadeInUp mini:mx-auto mini:vw-max-w mini:[--max-w:328] tablet:[--max-w:360] laptop:[--max-w:470] desktop:[--max-w:560]"
              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "career_details.cta.title" })  }}>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerDetails;
