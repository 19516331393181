import React from "react";
import slugify from "slugify";
import { getDepartmentBgColorClass, formatSalaryPublic } from "bundles/common/utils/helpers";

const RelatedJobs = ({ data }) => {
  const slugifyOptions = {
    lower: true,
    strict: true,
    remove: /[*+~.()'"!:@]/g
  };

  return (
    <section id="related-jobs" className={`vw-py [--py:70] mini:[--py:110] tablet:[--py:140] desktop:[--py:170] ${data.additionalClasses}`}>
      <div id="related-jobs-container" className="flex flex-col vw-gap [--gap:40] mini:[--gap:64] tablet:[--gap:80] laptop:[--gap:64]">
        <h3 id="related-jobs-title" className="h2 vw-px [--px:16] mini:[--px:52] tablet:[--px:84] laptop:[--px:110] desktop:[--px:148] fadeInUp">
          {data.title}
        </h3>
        <div id="related-jobs-swiper" className="swiper w-full vw-px [--px:16] mini:[--px:52] tablet:[--px:84] laptop:[--px:110] desktop:[--px:148] select-none">
          <div className="swiper-wrapper flex">
            {data.cards.map((card, index) => (
              <div key={index} className="swiper-slide shrink-0 vw-w [--w:264] mini:[--w:323] tablet:[--w:270] laptop:[--w:370] desktop:[--w:377]">
                <a href={`/jobs/${slugify(card.job_title, slugifyOptions)}/${card.id}`} id="job-listing" className="col-span-12 tablet:col-span-8 h-full vw-p [--p:20] laptop:[--p:26] vw-rounded-tr [--rounded-tr:50] laptop:[--rounded-tr:60] vw-rounded-bl [--rounded-bl:50] laptop:[--rounded-bl:60] border border-lightBlue hover:border-deepBlue/50 flex flex-col items-center justify-between vw-gap [--gap:30] transition-colors duration-150 ease-out-cubic fadeInUp">
                  <div id="job-listing-head" className="flex flex-col items-start justify-start w-full vw-gap [--gap:20] laptop:[--gap:24] grow">
                    <div id="job-listing-tag" className={`inline-block vw-text [--text:14] leading-none vw-px [--px:8] vw-py [--py:4] text-darkGreen ${getDepartmentBgColorClass(card?.category?.department?.code)}`}>
                      {card.category?.name}
                    </div>
                    <div id="job-listing-title" className="w-full h-[2lh] line-clamp-2 category-title text-darkGreen">
                      {card.job_title}
                    </div>
                    {card.salary_type !== "no-salary" &&
                      <div id="job-listing-salary" className="vw-text [--text:14] vw-leading [--leading:16] text-deepBlue">
                        {formatSalaryPublic(card.salary_type, card.min_salary, card.max_salary)}
                      </div>
                    }
                    <div id="job-listing-infos" className="flex flex-col vw-gap [--gap:6]">
                      <div id="job-listing-location" className="inline-flex justify-start items-center vw-gap [--gap:12] vw-text [--text:14] vw-leading [--leading:16] text-deepBlue">
                        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M0.318008 7.82315C0.318008 3.90339 3.33913 0.513428 7.39598 0.513428C11.4363 0.513428 14.4707 3.957 14.4707 7.82315C14.4707 12.7962 7.39598 18.5134 7.39598 18.5134C7.39598 18.5134 0.318008 12.7962 0.318008 7.82315ZM7.39494 9.23956C8.34352 9.23956 9.1125 8.47058 9.1125 7.522C9.1125 6.57342 8.34352 5.80444 7.39494 5.80444C6.44636 5.80444 5.67738 6.57342 5.67738 7.522C5.67738 8.47058 6.44636 9.23956 7.39494 9.23956Z" fill="#4CC1AE" />
                        </svg>
                        <span>
                          {card.city}
                        </span>
                      </div>
                      <div id="job-listing-type" className="inline-flex justify-start items-center vw-gap [--gap:12] vw-text [--text:14] vw-leading [--leading:16] text-deepBlue">
                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.33975 1.51343L1.31801 6.53517V17.4917C1.31801 17.7742 1.43023 18.0451 1.63 18.2449C1.82977 18.4447 2.10071 18.5569 2.38323 18.5569H14.2528C14.5353 18.5569 14.8062 18.4447 15.006 18.2449C15.2058 18.0451 15.318 17.7742 15.318 17.4917V2.57865C15.318 2.29613 15.2058 2.02519 15.006 1.82542C14.8062 1.62565 14.5353 1.51343 14.2528 1.51343H6.33975Z" stroke="#4CC1AE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1.47296 6.53517H5.04905C5.43246 6.53517 5.80017 6.38286 6.07128 6.11175C6.34239 5.84063 6.4947 5.47293 6.4947 5.08951V1.51343L1.47296 6.53517Z" fill="#4CC1AE" stroke="#4CC1AE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span>
                          {card.job_status.charAt(0).toUpperCase() + card.job_status.slice(1)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr className="w-full border-darkGreen/50" />
                  <div id="job-listing-bottom" className="flex justify-between items-center w-full max-tablet:mini:vw-max-w mini:max-tablet:[--max-w:230]">
                    <div id="job-listing-button">
                      <div className="inline-flex vw-gap [--gap:6] items-center justify-start text-deepBlue button-cta !leading-none">
                        <span>
                          {data.button_label}
                        </span>
                        <svg className="vw-w [--w:20] object-contain overflow-visible" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.5" y="1.18213" width="18.5" height="18.5" rx="9.25" stroke="#CAE5FF" />
                          <path d="M8.75 6.68213L12.5 10.4321L8.75 14.1821" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                    </div>
                    {card.user && (
                      <div id="job-listing-manager" className="flex items-center justify-end vw-gap [--gap:10]">
                        <div id="job-listing-manager-name" className="text-right font-light vw-text [--text:10] leading-loose">
                          {data.managed_by_label}
                          <br />
                          {card.user.first_name} {card.user.last_name}
                        </div>
                        <div id="job-listing-manager-image" className="aspect-square vw-w [--w:44] rounded-full overflow-hidden shrink-0">
                          <img
                            className="w-full h-full object-cover"
                            src={card.user.avatar_url}
                            alt={`${card.user.first_name}'s profile picture`} loading="lazy"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </a>
              </div>
            ))}
          </div>
          <div id="related-jobs-swiper-bottom" className="flex items-center vw-gap [--gap:32] vw-mt [--mt:48]">
            <div id="related-jobs-swiper-bottom-decoration" className="w-full bg-darkGreen/50 grow h-[1px]"></div>
            <div id="related-jobs-pagination" className="swiper-pagination shrink-0 font-extralight vw-text [--text:18] vw-tracking [--tracking:3] text-darkGreen"></div>
            <div id="related-jobs-navigation" className="swiper-navigation shrink-0 inline-flex items-center vw-gap [--gap:14]">
              <div id="related-jobs-prev" className="swiper-button-prev cursor-pointer rounded-full border border-lightBlue bg-transparent text-deepBlue transition-colors duration-150 ease-out-cubic hover:bg-deepBlue hover:border-deepBlue hover:text-white">
                <svg className="vw-w [--w:32] aspect-square object-contain" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.1112 18.5L12.3612 14.75L16.1112 11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div id="related-jobs-next" className="swiper-button-next cursor-pointer rounded-full border border-lightBlue bg-transparent text-deepBlue transition-colors duration-150 ease-out-cubic hover:bg-deepBlue hover:border-deepBlue hover:text-white">
                <svg className="vw-w [--w:32] aspect-square object-contain" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.6112 11L19.3612 14.75L15.6112 18.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {data.button && (
          <div id="related-jobs-cta" className="vw-px [--px:16] mini:[--px:52] tablet:[--px:84] laptop:[--px:110] desktop:[--px:148]">
            <a href={data.button.url} className="button max-mini:w-full border-deepBlue bg-deepBlue text-white hover:border-lightBlue hover:bg-lightBlue hover:text-deepBlue">
              <svg className="button-icon" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="button-text">
                {data.button.title}
              </span>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default RelatedJobs;
