import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import validator from "validator";
import { Box, Grid, Button, CircularProgress, TextField, FormControlLabel, Radio, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import { userRoles, offices } from "bundles/common/utils/constants";
import WelcomeImg from "internal/happy-announcement.svg";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    height: "calc(100vh - 64px)",
    backgroundColor: "white",
    display: "flex"
  },
  sideBox: {
    width: "50%",
    overflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
  },
  formBox: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 64px)",
  },
  loginCard: {
    width: "440px"
  }
}));

const Invitation = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    email: "",
    first_name: "",
    last_name: "",
    main_office: "",
    role: "",
    regional_department_ids: [],
    manager_id: null,
  };

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [managers, setManagers] = useState([]);
  const [regionalDepartments, setRegionalDepartments] = useState([]);
  const [userRegionalDepartments, setUserRegionalDepartments] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let notAnEmail = "This does not look like an email";

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? false : true;
      if (fieldValues.email && !validator.isEmail(fieldValues.email))
        temp.email = notAnEmail;
    }

    if ("first_name" in fieldValues) {
      temp.first_name = fieldValues.first_name ? false : true;
    }

    if ("last_name" in fieldValues) {
      temp.last_name = fieldValues.last_name ? false : true;
    }

    if ("main_office" in fieldValues) {
      temp.main_office = fieldValues.main_office ? false : true;
    }

    if ("role" in fieldValues) {
      temp.role = fieldValues.role ? false : true;
    }

    if ("regional_department_ids" in fieldValues) {
      temp.regional_department_ids = fieldValues.regional_department_ids.length > 0 ? false : true;
    }

    if ("manager_id" in fieldValues) {
      temp.manager_id = fieldValues.manager_id ? false : true;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === false);
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name !== "email") {
      validate({ [name]: value });
    }
  };

  const getAllManagers = () => {
    sh.get("internal/all_managers").then((res) => {
      setManagers(res?.data?.managers || []);
    });
  };

  const getAllRegionalDepartments = () => {
    sh.get("internal/all_regional_departments").then((res) => {
      setRegionalDepartments(res?.data?.regional_departments || []);
    });
  };

  const handleRegionalDepartmentsChange = (latestSelectedId) => {
    const isAlreadySelected = userRegionalDepartments.some(department => department.id === latestSelectedId);
    let newSelection;

    if (isAlreadySelected) {
      if (userRegionalDepartments.length > 1) {
        newSelection = userRegionalDepartments.filter(department => department.id !== latestSelectedId);
      } else {
        newSelection = [...userRegionalDepartments];
      }
    } else {
      const newDepartment = regionalDepartments.find(department => department.id === latestSelectedId);
      newSelection = newDepartment ? [...userRegionalDepartments, newDepartment] : [...userRegionalDepartments];
    }


    setUserRegionalDepartments(newSelection);

    setValues({
      ...values,
      regional_department_ids: newSelection.map(department => department.id)
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    for (const fieldName in values) {
      validate({ [fieldName]: values[fieldName] });
    }
    if (validate(values)) {
      let userValues = values;
      if (userValues["manager_id"] == -1) {
        userValues["manager_id"] = null;
      }
      sh.post("/users/invitation", {
        authenticity_token: authenticityToken,
        user: userValues,
      })
        .then(() => {
          setLoading(false);
          enqueueSnackbar("Invitation sent!", {
            variant: "success",
          });
          setTimeout(() => {
            navigate("/internal/employees");
          }, 500);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllManagers();
    getAllRegionalDepartments();
  }, []);

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.sideBox}>
        <h1 style={{ marginBottom: "64px" }}>Invite a new teammate</h1>
        <img style={{ height: "50%" }} src={WelcomeImg} />
      </Box>
      <Box className={classes.formBox}>
        <form onSubmit={handleSubmit} style={{ width: "min(50%, 600px)"}}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                type="email"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name="email"
                label="Email"
                {...(errors["email"] && {
                  error: true,
                  helperText: errors["email"],
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name="first_name"
                label="First name"
                {...(errors["first_name"] && {
                  error: true,
                  helperText: errors["first_name"],
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name="last_name"
                label="Last name"
                {...(errors["last_name"] && {
                  error: true,
                  helperText: errors["last_name"],
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                size="small"
                label="Main Office"
                name="main_office"
                value={values.main_office}
                onChange={handleInputValue}
                {...(errors["main_office"] && {
                  error: true,
                  helperText: errors["main_office"],
                })}
              >
                {offices.map((office) => {
                  return (
                    <MenuItem value={office.code} key={office.code}>
                      {office.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                size="small"
                label="Role"
                name="role"
                value={values.role}
                onChange={handleInputValue}
                {...(errors["role"] && {
                  error: true,
                  helperText: errors["role"],
                })}
              >
                {userRoles.map((role) => {
                  return (
                    <MenuItem value={role.code} key={role.code}>
                      {role.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => loading ? "" : `${selected.length} selected`
                }}
                fullWidth
                size="small"
                label="Regional departments"
                name="regional_department_ids"
                value={values.regional_department_ids}
                {...(errors["regional_department_ids"] && {
                  error: true,
                  helperText: errors["regional_department_ids"],
                })}
              >
                {regionalDepartments.map((regionalDepartment) => (
                  <MenuItem
                    key={regionalDepartment.id}
                    value={regionalDepartment}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={userRegionalDepartments.some(department => department.id === regionalDepartment.id)}
                          onClick={() => handleRegionalDepartmentsChange(regionalDepartment.id)}
                        />
                      }
                      label={regionalDepartment.name}
                    />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                size="small"
                label="Manager"
                name="manager_id"
                value={values.manager_id}
                onChange={handleInputValue}
                {...(errors["manager_id"] && {
                  error: true,
                  helperText: errors["manager_id"],
                })}
              >
                <MenuItem value={-1}>
                  No manager
                </MenuItem>
                {managers.map((manager) => {
                  return (
                    <MenuItem value={manager.id} key={manager.email}>
                      {manager.first_name}{" "}{manager.last_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="rounded"
                color="primaryContained"
                size="medium"
                disabled={loading}
              >
                <Box justifyContent="center">
                  {loading && (
                    <CircularProgress size={16} color="secondary">
                      Sending invitation…
                    </CircularProgress>
                  )}
                  {!loading && "Invite"}
                </Box>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Invitation;
